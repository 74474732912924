header{
    padding: 20px 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    background: #fff;
    top: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

header .nav .navbar-logo img{
    width: 200px;
    height:auto;
}