@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
html{
    -webkit-text-size-adjust:none;
    font-size:10px;
    -webkit-font-smoothing:antialiased;
}
html body{
    background-color:#fff;
    color:#000000;
    font-family: 'Roboto', sans-serif;
    font-weight:400;
    font-size:1.6rem;
    line-height:2.4rem;
    margin: 0;
}

.pt-0{
    padding-top: 0 !important;
}
.pb-0{
    padding-bottom: 0 !important;
}
.pb-100{
    padding-bottom: 100px !important;
}

.main-content-wrapper{
    margin-top: 100px;
}

.component-section{
    padding: 50px 0;
}