input.form-control{
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 20px;
}
textarea.form-control{
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 20px;
}
input.btn.btn-info{
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 20px;
    background: #132158;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    outline: none;
    border: none;
    margin-top: 30px 
}